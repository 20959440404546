import { useRef, useState } from "react";
import banner from "../assets/vedios/about.webm";
import { cls } from "./Utility";
import wedding from "../assets/pictures/wedding.webp";
import prewedding from "../assets/pictures/others4.webp";
import engagement from "../assets/pictures/engagement1.webp";
import haldi from "../assets/pictures/haldi2.webp";
import sangeeth from "../assets/pictures/sangeeth1.webp";
import mehandi from "../assets/pictures/others6.webp";
import ContinuousScroll from "./ContinuousScroll";
import insta1 from "../assets/pictures/insta1.webp";
import insta2 from "../assets/pictures/insta2.webp";
import insta3 from "../assets/pictures/insta3.webp";
import insta4 from "../assets/pictures/insta4.webp";
import insta5 from "../assets/pictures/insta5.webp";
import insta6 from "../assets/pictures/insta6.webp";
import insta7 from "../assets/pictures/insta7 (1).webp";
import insta8 from "../assets/pictures/insta8.webp";
import insta9 from "../assets/pictures/insta9.webp";
import insta10 from "../assets/pictures/insta10.webp";
import ReactPlayer from "react-player";
import blog1 from "../assets/pictures/Prashanth - AK.webp";
import blog2 from "../assets/pictures/Sowmya - Nihal.webp";
import blog3 from "../assets/pictures/Amulya - Bharath (1).webp";
import DesktopWa from "./DesktopWa";
import Call from "./Call";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const eventdata = [
  {
    src: wedding,
    title: "Wedding",
  },
  {
    src: prewedding,
    title: "Pre-Wedding",
  },
  {
    src: engagement,
    title: "Engagement",
  },
  {
    src: haldi,
    title: "Haldi",
  },
  {
    src: sangeeth,
    title: "Sangeeth",
  },
  {
    src: mehandi,
    title: "Mehandi",
  },
];
const blogData = [
  {
    img: blog1,
    couple_name: "Prashanth & Akshitha",
    event_name: "Pre-Wedding",
    description:
      "Services offer a seamless blend of professionalism and personal touch, ensuring every aspect of your special day is executed flawlessly.",
  },
  {
    img: blog2,
    couple_name: "Sowmya & Nihal",
    event_name: "Wedding",
    description:
      "wedding services offer a perfect blend of professionalism and personal care, ensuring flawlessly executed moments on your special day.",
  },
  {
    img: blog3,
    couple_name: "Amulya & Bharath",
    event_name: "Wedding",
    description:
      "wedding services offer a perfect blend of professionalism and personal care, ensuring flawlessly executed moments on your special day.",
  },
];
const vedioData = [
  "https://youtu.be/9z-rkwsu5R8",
  "https://youtu.be/Xs9fWdY5gNw",
  "https://youtu.be/g8VWUK_FjDY",
  "https://youtu.be/_NetCWf1s9w",
  "https://youtu.be/KY3XnOs_roE",
  "https://youtu.be/s4-FB2D7gPM",
  "https://youtu.be/CXaP6UJbhz4",
  "https://youtu.be/ps-a1pMCtzc",
  "https://youtu.be/detGdQxokGY",
];

const imageSources = [
  insta1,
  insta2,
  insta10,
  insta4,
  insta5,
  insta6,
  insta7,
  insta8,
  insta9,
];

const imageSources1 = [
  insta1,
  insta2,
  insta3,
  insta10,
  insta4,
  insta5,
  insta6,
  insta7,
  insta8,
  insta9,
];

export default function Landing() {
  const [result, setResult] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current.scrollIntoView();
  };
  const [formData, setFormData] = useState({
    name: "",
    event: "",
    happeningDate: "",
    countryCode: "+91",
    phoneNumber: "",
    city: "",
    message:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      happeningDate: date,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    setShowMessage(true);

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append("access_key", "bbfedd98-01de-4af0-8bda-388396a1dff8");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formDataToSend,
      });
      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        setFormData({
          name: "",
          event: "",
          countryCode: "+91",
          phoneNumber: "",
          city: "",
          message:''
        });
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setResult("An error occurred. Please try again later.");
    }

    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[40px]">
        <div className="w-full h-[650px] xl:h-full overflow-hidden xl:relative block">
          <video autoPlay loop muted className="w-full h-full object-cover">
            <source src={banner} type="video/mp4" />
          </video>
        </div>
        <p
          id="typing-text"
          class="absolute xl:top-[300px] xl:left-[50px] top-[280px] left-[55px] text-white xl:text-[40px] text-[18px] font-[Lemajor] font-bold tracking-widest italic hidden xl:block"
        >
          Premium Wedding Photography
        </p>
        <div>
          <p
            className="xl:text-[30px] text-[18px] xl:pl-[25px] xl:pr-[25px] pl-[15px] pr-[15px] leading-[37px] xl:leading-none
          font-[Lemajor] mt-[30px] font-bold text-center tracking-widest"
          >
            Creating Timeless Memories: Our Suite of Unforgettable Events
          </p>
          <p className="pl-[15px] pr-[15px] text-[16px] xl:pl-[100px] xl:pr-[100px] xl:text-center font-[Quicksand] mt-[30px] text-[#847f81]">
            Crafting everlasting moments, our suite of unforgettable events
            offers bespoke experiences tailored to your vision. From exquisite
            weddings to corporate galas, our team ensures every detail exceeds
            expectations. With meticulous planning and impeccable service, we
            create cherished memories that linger in the hearts of guests. Trust
            us to turn your dreams into timeless realities.
          </p>
          <div className="xl:flex-row xl:gap-[15px] xl:justify-center mt-[30px] xl:pl-[20px] xl:pr-[20px] xl:flex gap-[15px] pl-[15px] pr-[15px] flex flex-col ">
            {eventdata.map((item, index) => (
              <div
                key={index}
                className=" flex flex-col gap-[10px] items-center"
              >
                <img
                  src={item.src}
                  alt="wedding"
                  className="xl:w-[250px] w-[400px] xl:h-[150px] h-[400px] object-cover rounded-xl"
                />
                <p className="text-[16px] font-[Quicksandbold]">{item.title}</p>
              </div>
            ))}
          </div>
        </div>
        <p
          
          className="font-[Lemajor] xl:text-[30px] text-[18px] xl:pl-[25px] xl:pr-[25px] pl-[15px] pr-[15px] tracking-widest mt-[40px] text-center leading-[37px] xl:leading-none"
        >
          Reach Out for Your Dream Wedding Photography Experience....!
        </p>
        <p className="mt-[30px] font-[Quicksand] text-[16px] xl:text-center xl:pl-[100px] xl:pr-[100px] text-[#847f81] pl-[15px] pr-[15px]">
          Immerse yourself in the magic of your special day with our
          unparalleled wedding photography services. From candid moments to
          timeless portraits, we capture every cherished memory with artistry
          and precision. Let us transform your dreams into breathtaking visual
          narratives, ensuring your love story shines eternally. Reach out today
          and embark on a journey to relive the romance and beauty of your
          wedding day, captured flawlessly by our expert team.
        </p>
        <button
          className="instant-quote-button font-[Quicksand]"
          onClick={scrollToForm}
        >
          Get an Instant Quote
        </button>
        <div
          className={cls(
            "mt-[30px] flex flex-col items-center pl-[15px] pr-[15px]",
            "xl:flex-row xl:justify-center xl:items-center xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          <div ref={formRef} className="w-full border-[#A0A0A0] border-[1px] rounded-[45px] flex flex-col p-[25px] gap-[15px] xl:p-[35px] xl:max-w-[600px]">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-[20px]">
                <input
                  type="text"
                  name="name"
                  autoComplete="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="border-b-[1px] border-[#875239] rounded-xl w-full p-[10px] outline-none placeholder:text-sky-950 text-[16px] font-[Quicksand]"
                  placeholder="Name*"
                />

                <div className="flex gap-[20px]  font-[Quicksand]">
                  <label htmlFor="countryCode" className="sr-only">
                    Country Code:
                  </label>
                  <select
                    id="countryCode"
                    name="countryCode"
                    autoComplete="country code"
                    value={formData.countryCode}
                    onChange={handleChange}
                    className="border-b-[1px] border-[#875239] rounded-xl p-[10px] outline-none placeholder:text-sky-950 text-[16px] w-[100px]"
                  >
                    <option value="+91">+91</option>
                    <option value="+1">+1</option>
                    <option value="+44">+44</option>
                    <option value="+61">+61</option>
                    <option value="+86">+86</option>
                    <option value="+33">+33</option>
                    <option value="+49">+49</option>
                    <option value="+81">+81</option>
                    <option value="+7">+7</option>
                    <option value="+82">+82</option>
                  </select>

                  <input
                    type="tel"
                    autoComplete="phone number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                    className="border-b-[1px] border-[#875239] rounded-xl p-[10px] outline-none placeholder:text-sky-950 text-[16px] w-full"
                    placeholder="Your Number*"
                  />
                </div>
                <input
                  type="text"
                  name="event"
                  autoComplete="event"
                  value={formData.event}
                  onChange={handleChange}
                  className="border-b-[1px] border-[#875239] rounded-xl w-full p-[10px] outline-none placeholder:text-sky-950 text-[16px] font-[Quicksand]"
                  placeholder="Event"
                />
                <DatePicker
                  selected={formData.happeningDate}
                  onChange={handleDateChange}
                  className="border-b-[1px] border-[#875239] rounded-xl p-[10px] outline-none placeholder:text-sky-950 text-[16px] w-full font-[Quicksand] font-normal"
                  dateFormat="dd/MM/yyyy"
                  id="happeningDate"
                  name="happeningDate"
                  autoComplete="happeningDate"
                  placeholderText="Happening Date"
                />
                <input
                  type="text"
                  name="city"
                  autoComplete="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  className="border-b-[1px] border-[#875239] rounded-xl  w-full p-[10px] outline-none placeholder:text-sky-950 text-[16px] font-[Quicksand]"
                  placeholder="City*"
                />
                <textarea
                  name="message"
                  autoComplete="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="border-b-[1px] border-[#875239] rounded-xl w-full p-[10px] outline-none placeholder:text-sky-950 text-[16px] font-[Quicksand]"
                  placeholder="Message"
                />
              </div>

              <div className="flex justify-center items-center mt-[30px]">
                <button
                  type="submit"
                  className="bg-[#875339] text-white  font-[Quicksand] pl-[15px] pr-[15px] text-center mt-[20px] w-[250px] pt-[10px] pb-[10px] text-[16px] rounded-xl "
                >
                  Submit
                </button>
              </div>
            </form>
            {showMessage && (
              <div className="w-full flex justify-center items-center text-[#A4a4a4] font-[Quicksand]">
                Form submitted successfully...
              </div>
            )}
          </div>
        </div>

        <p className="mt-[30px] mb-[10px] xl:text-[30px] text-[18px] xl:leading-none leading-[37px] text-center font-[Lemajor] tracking-widest xl:pl-[25px] xl:pr-[25px] pl-[15px] pr-[15px]">
          Have an Eye Feast With adorable gallery...
        </p>
        <div className="text-[#847f81] font-[Quicksand] text-[20px] text-center mt-[20px] mb-[20px]  xl:pl-[25px] xl:pr-[25px]">
          Want to explore more about our adorable work check it out at our
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            <span className="text-[#875239] uppercase ml-[10px]">
              instagram
            </span>
          </a>{" "}
          ...!
        </div>
        <div className="xl:block hidden">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ContinuousScroll>
              <div
                style={{
                  width: "800px",
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "1px",
                  padding: "1px",
                }}
              >
                {imageSources.map((src, index) => (
                  <a
                    key={index}
                    href="https://www.instagram.com/storiesby.rg/"
                    aria-label="click here"
                  >
                    <img
                      src={src}
                      alt="images"
                      className="hover:scale-95 ease-in-out"
                    />
                  </a>
                ))}
              </div>
            </ContinuousScroll>
          </div>
        </div>

        <div className="xl:hidden block">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <ContinuousScroll>
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "1px",
                  padding: "1px",
                }}
              >
                {imageSources1.map((src, index) => (
                  <a
                    key={index}
                    href="https://www.instagram.com/storiesby.rg/"
                    aria-label="click here"
                  >
                    <img
                      src={src}
                      alt="images_insta"
                      className="hover:scale-95 ease-in-out"
                    />
                  </a>
                ))}
              </div>
            </ContinuousScroll>
          </div>
        </div>

        <p className="xl:text-[30px] font-[Lemajor] mt-[30px] font-bold text-center tracking-widest text-[18px] xl:leading-none leading-[37px] xl:pl-[25px] xl:pr-[25px] pl-[l5px] pr-[15px]">
          Unveiling Our Spectacular Wedding Photography Showcase?
        </p>
        <p className="text-[16px] mt-[30px] xl:pl-[100px] xl:pr-[100px] xl:text-center pl-[15px] pr-[15px] font-[Quicksand] text-[#847f81]">
          Step into a world of enchantment as we unveil our spectacular wedding
          photography showcase. Capturing love in its purest form, our
          collection showcases timeless moments frozen in time. From candid
          embraces to breathtaking vistas, each image tells a story of romance
          and devotion. Immerse yourself in our portfolio and let us preserve
          your special day with artistic flair and passion.
        </p>
        <div className="hidden xl:flex xl:justify-center xl:items-center xl:mt-[30px]">
          <div
            className={cls(
              "hidden",
              "xl:pl-[25px] xl:pr-[25px] xl:grid xl:grid-cols-3 xl:gap-[10px]"
            )}
          >
            {vedioData.map((item, index) => (
              <div
                className="rounded-2xl overflow-hidden object-cover"
                key={index}
              >
                <ReactPlayer url={item} height={200} width={400} volume={1} />
              </div>
            ))}
          </div>
        </div>

        <div
          className={cls(
            "flex flex-col gap-[15px] mt-[30px] pl-[15px] pr-[15px]",
            "xl:hidden"
          )}
        >
          {vedioData.map((item, index) => (
            <div
              className="rounded-2xl overflow-hidden object-cover"
              key={index}
            >
              <ReactPlayer url={item} height={200} width={400} volume={1} />
            </div>
          ))}
        </div>

        <div className="mt-[30px]">
          <p className="xl:text-[30px] text-[18px] font-[Lemajor] tracking-[6px] mb-[30px] text-center xl:leading-none leading-[37px] xl:pl-[25px] xl:pr-[25px] pl-[15px] pr-[15px]">
            Glance At What Our Client Says...!
          </p>
          <p className="text-[16px] font-[Quicksand] xl:pl-[100px] xl:pr-[100px] xl:text-center pl-[15px] pr-[15px] text-[#847f81]">
            Step into the world of enchantment as our clients share their
            heartfelt experiences. Discover the magic woven into each
            testimonial, painting a vivid picture of unparalleled service and
            satisfaction. Let their words guide you through a journey of
            elegance and excellence. Explore, indulge, and be inspired by the
            stories that speak volumes about our commitment to perfection.
          </p>
          <div className="xl:pl-[25px] xl:pr-[25px] xl:flex-row gap-[15px] xl:gap-[40px] xl:justify-center mt-[30px] pl-[15px] pr-[15px] flex flex-col items-center">
            {blogData.map((item, index) => (
              <div
                key={index}
                className="xl:max-w-[400px] h-[370px] max-w-full flex flex-col bg-white rounded-tl-[25px] rounded-tr-[25px] rounded-br-[85px] rounded-bl-[65px] overflow-hidden shadow-sm shadow-white"
              >
                <div className="w-full bg-[#875239] h-[100px] relative flex items-center justify-center">
                  <img
                    src={item.img}
                    className="absolute left-[35%] xl:left-[35%] md:left-[43%] top-[50%] rounded-full w-[120px] h-[120px] object-cover border-[4px] border-white"
                    alt={item.couple_name}
                  />
                </div>
                <div className="mt-[80px] flex flex-col items-center text-center gap-[20px] pl-[25px] pr-[25px] pb-[20px]">
                  <p className="font-bold text-[20px] text-black font-[Quicksandbold]">
                    {item.couple_name}
                  </p>
                  <p className="text-[18px] text-[#847f81] font-[Quicksandbold]">
                    {item.event_name}
                  </p>
                  <p className="text-[16px] font-[Quicksand]">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-[40px] text-center mb-[20px]">
          <p className="font-semibold xl:text-[40px] text-[30px] text-[#875239] mb-[10px] ">
            Call for free consultation at
          </p>
          <div className="font-semibold xl:text-[40px] text-[30px] text-[#875239] mb-[10px] ">
            <Call phoneNumber="+91 9353624245" />
          </div>
        </div>

        <div className="chat-icon">
          <DesktopWa />
        </div>
      </div>
    </>
  );
}
