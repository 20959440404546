import { BiLogoInstagramAlt } from "react-icons/bi";
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialLinkedin } from "react-icons/ti";
import { AiFillYoutube } from "react-icons/ai";
import { RiTwitterXFill } from "react-icons/ri";
import { cls } from "./Utility";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="w-full bg-white xl:p-[20px] p-[15px]">
      <div className="flex justify-center">
        <div className="flex gap-[15px]">
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="Read more"
          >
            <BiLogoInstagramAlt
              size={26}
              className="text-black"
              aria-label="button"
            />
          </a>
          <a
            href="https://www.facebook.com/storiesbyrg/"
            aria-label="Read more"
          >
            <TiSocialFacebook
              size={26}
              className="text-black"
              aria-label="button"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/stories-by-rg/?viewAsMember=true"
            aria-label="Read more"
          >
            <TiSocialLinkedin
              size={26}
              className="text-black"
              aria-label="button"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCn9GaUS7TlcMtuPiZ-PuOLg"
            aria-label="Read more"
          >
            <AiFillYoutube
              size={26}
              className="text-black "
              aria-label="button"
            />
          </a>
          <a href="https://twitter.com/storiesbyrg " aria-label="Read more">
            <RiTwitterXFill
              size={18}
              className="text-black mt-1"
              aria-label="button"
            />
          </a>
        </div>
      </div>
      <div
        className={cls(
          "flex flex-col items-center mt-[15px]",
          "xl:flex-row xl:justify-center"
        )}
      >
        <div className="xl:flex-row flex flex-col gap-[15px] text-[14px] font-[Quicksand]">
          <Link to="/terms-and-conditions"><p> Terms & Conditions </p></Link>
          <Link to="/privacy-policy"><p>Privacy Policy</p></Link>
          <Link to="/cancellation-and-refund"><p>Cancellation & Refund</p></Link>
        </div>
      </div>
      <div className="flex justify-center mt-[15px] text-[14px] font-[Quicksand]">
        © 2024 All rights reserved
      </div>
    </div>
  );
}
