import React from "react";

const Call = ({ phoneNumber }) => {
  const handleCall = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div onClick={handleCall} style={{ cursor: "pointer" }}>
      {phoneNumber}
    </div>
  );
};

export default Call;
