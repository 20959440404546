import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import Landing from "./components/Landing";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CancellationAndRefund from "./components/CancellationAndRefund";

function ScrollToTopOnRouteChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTopOnRouteChange />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cancellation-and-refund" element={<CancellationAndRefund/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
