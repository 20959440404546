import { useState, useEffect } from "react";
import company_logo from "../assets/pictures/logo.webp";

const Navbar = () => {

  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled === 0) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`bg-transparent w-full fixed top-0 z-10 transition-all duration-300  ${
          isTop ? "" : "transform -translate-y-full"
        }`}
      >
        <div className="w-full mx-auto px-4 relative">
          <div className="flex justify-center items-center">
            <div className="flex items-center">
              <a href="/">
                <img
                  src={company_logo}
                  alt="Company Logo"
                  className="w-full max-h-[100px] object-contain sm:w-[100px] sm:max-h-[100px] "
                />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Navbar;
