import { useEffect, useRef } from "react";

const ContinuousScroll = ({ children }) => {
  const containerRef = useRef(null);
  let lastTouchY = useRef(null);

  const cloneChildren = () => {
    const container = containerRef.current;
    const originalChildren = container.children;
    const childrenLength = originalChildren.length;

    // Calculate total height of original children
    let totalHeight = 0;
    for (let i = 0; i < childrenLength; i++) {
      totalHeight += originalChildren[i].offsetHeight;
    }

    // Clone original children to fill the container
    while (totalHeight < container.clientHeight) {
      for (let i = 0; i < childrenLength; i++) {
        const clone = originalChildren[i].cloneNode(true);
        container.appendChild(clone);
        totalHeight += clone.offsetHeight;
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    const scroll = () => {
      container.scrollTop += 1;
      if (container.scrollTop >= container.scrollHeight / 2) {
        cloneChildren();
        container.scrollTop = 0;
      }
    };

    cloneChildren();
    const interval = setInterval(scroll, 10);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // Reinitialize scrolling behavior on resize
      const container = containerRef.current;
      container.scrollTop = 0; // Reset scroll position
      cloneChildren(); // Recalculate children to fill container
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTouchStart = (e) => {
    lastTouchY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    if (lastTouchY.current !== null) {
      const deltaY = e.touches[0].clientY - lastTouchY.current;
      containerRef.current.scrollTop -= deltaY;
      lastTouchY.current = e.touches[0].clientY;
      e.preventDefault();
    }
  };

  const handleTouchEnd = () => {
    lastTouchY.current = null;
  };

  return (
    <div
      ref={containerRef}
      className="scroll-container"
      style={{ overflow: 'hidden' }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
};

export default ContinuousScroll;
